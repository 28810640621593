import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginCall } from './userApi';
import { useNavigate } from 'react-router-dom';
import { deleteToken, setToken } from 'src/utils/extractUserToken';
import axios from 'axios';

const initialState = {
  isAuthenticated: false,
  userInfo: {},
  userError: {
    loginError: false,
    loginErrorMessage: '',
  },
  trackingInfo: [],
  mailScan: [],
  replacementColumns: [],
};

export const login = createAsyncThunk('user/login', async (data) => {
  const response = await loginCall(data);
  return {
    ...response,
    trackingInfo: response.data.user_preferences.tracking_info,
    mailScan: response.data.user_preferences.mail_scan,
    replacementColumns: response.data.user_preferences.replacement_orders,
  };
});

export const logoutUser = createAsyncThunk('user/fetchUserLogout', async () => {
  return initialState;
});

export const updateUserPreferences = createAsyncThunk(
  'user/updateUserPreferences',
  async ({ trackingInfo, mailScan, replacementColumns }, { getState }) => {
    const currentState = getState();
    return {
      ...currentState.user,
      trackingInfo,
      mailScan,
      replacementColumns,
    };
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    selectProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.userInfo = action.payload.data;
        state.isAuthenticated = true;
        state.userError = { ...state.userError, loginError: false };
        state.trackingInfo = action.payload.trackingInfo;
        state.mailScan = action.payload.mailScan;
        state.replacementColumns = action.payload.replacementColumns;
        setToken(action?.payload?.data?.accessToken);
      })
      .addCase(login.rejected, (state, action) => {
        state.userError = {
          ...state.userError,
          loginError: true,
          loginErrorMessage:
            action?.error?.message == 'Invalid credentials'
              ? 'Invalid User name or Password'
              : action?.error?.message || 'Incorrect User name or Password',
        };
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.isAuthenticated = initialState.isAuthenticated;
        state.userInfo = initialState.userInfo;
        state.trackingInfo = initialState.trackingInfo;
        state.mailScan = initialState.mailScan;
        state.replacementColumns = initialState.replacementColumns;
        deleteToken();
      })
      .addCase(logoutUser.rejected, (state, action) => {
        console.log(action.error);
      })
      .addCase(updateUserPreferences.fulfilled, (state, action) => {
        state.trackingInfo = action.payload.trackingInfo;
        state.mailScan = action.payload.mailScan;
        state.replacementColumns = action.payload.replacementColumns;
      });
  },
});

export const { selectProduct } = userSlice.actions;

export const getUserInfo = (state) => state?.user?.userInfo;
export const getUserError = (state) => state?.user?.userError;

export default userSlice.reducer;
