import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { titleCase, removeTitleCase } from 'src/utils/core';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 195,
      color: '#1C272A',
    },
  },
};

export default function MultipleSelect({ columnsDetails, selectedColumns, handleColumnSubmit, sx = {} }) {
  const [tempSelectedColumns, setTempSelectedColumns] = useState(selectedColumns?.map((item) => titleCase(item)));
  const handleColumnChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedColumnTitles = typeof value === 'string' ? value.split(',') : value.map((item) => titleCase(item));
    const sortedColumns = selectedColumnTitles.sort((a, b) => {
      return b.localeCompare(a);
    });
    setTempSelectedColumns(sortedColumns);
  };
  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <FormControl
        sx={{
          width: { lg: 195, md: 150, sm: 80 },
          height: { lg: 40, sm: 33 },
          marginLeft: { lg: -15 },
          bgcolor: 'common.white',
          borderRadius: 1,
          '& .MuiInputBase-formControl': {
            paddingLeft: 0,
          },
          fontFamily: 'Whitney',
          ...sx,
        }}
        size="small"
      >
        <InputLabel
          sx={{
            color: 'common.black',
            fontSize: { sm: 14, lg: 16 },
            marginTop: { sm: -0.5, lg: 0 },
            fontFamily: 'Whitney',
            '&.MuiInputLabel-shrink': {
              display: 'none',
            },
          }}
          id="demo-multiple-checkbox-label"
        >
          Columns
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={tempSelectedColumns.map((item) => titleCase(item))}
          onChange={handleColumnChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{ color: 'common.black', paddingLeft: 2, height: { lg: 40, sm: 33 }, fontFamily: 'Whitney' }}
        >
          {columnsDetails.map((item) => (
            <MenuItem
              sx={{
                color: 'common.black',
                fontFamily: 'Whitney',
                marginLeft: '-15px',
                width: '200px',
              }}
              key={item}
              value={titleCase(item)}
            >
              <Checkbox checked={tempSelectedColumns.indexOf(titleCase(item)) > -1} />
              <ListItemText primary={titleCase(item).replace('Per', '%')} />
            </MenuItem>
          ))}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
        </Select>
      </FormControl>
      <Button
        type="button"
        variant="contained"
        onClick={() => handleColumnSubmit(tempSelectedColumns)}
        sx={{
          display: 'flex',
          width: { lg: '98px', md: '85px', sm: '63px' },
          height: { lg: '2.625em', sm: '33px' },
          padding: '0.5rem 1.75rem 0.625rem 1.813rem',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: 'none',
          flexShrink: 0,
          bgcolor: 'primary.light',
          textAlign: 'center',
          fontFamily: 'Whitney',
          fontSize: { lg: '16px !important', sm: '14px' },
          fontWeight: 500,
          ':hover': {
            backgroundColor: '#479da6d6',
          },
        }}
      >
        Apply
      </Button>
    </div>
  );
}

MultipleSelect.propTypes = {
  columnsDetails: PropTypes.array,
  selectedColumns: PropTypes.array,
  handleColumnSubmit: PropTypes.func,
};
