/* eslint-disable camelcase */
import {
  Stack,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Divider,
  Modal,
  IconButton,
  Card,
  CircularProgress,
} from '@mui/material';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from '@mui/lab';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { unixToDate, unixToTime, dateDiff } from '../../utils/core';
import SvgColor from '../svg-color/SvgColor';
import moment from 'moment';
import { useState } from 'react';

const popUpModalStyle = {
  // margin: { lg: '99px 157px 278px 157px' },
  position: 'absolute',
  top: { lg: '50%', md: '50%', sm: '49%' },
  left: { lg: '49%', md: '52%', sm: '50%' },
  transform: 'translate(-50%, -50%)',
  width: { lg: '78.19%', md: '78%', sm: '85%' },
  height: { lg: '90%', md: '65%', sm: '65%' },
  borderRadius: '20px',
  background: '#FFF',
  padding: '10px 0',
  overflow: 'auto',
  overflow: 'auto-y',
  overflowX: 'hidden',
  outline: 'none',
};

function OrderItem({ item, isLast, isFirst }) {
  const { date, location, status, mapped_category } = item;
  return (
    <TimelineItem sx={{ minHeight: 80 }}>
      <TimelineOppositeContent>
        <Stack
          spacing={0.5}
          alignItems="center"
          sx={{ width: 82 }}
          divider={<Divider flexItem sx={{ borderWidth: 1, bgcolor: '#ACACAC' }} />}
        >
          <Typography sx={{ fontFamily: 'Whitney', fontSize: { lg: 14, md: 12, sm: 10 } }}>
            {unixToDate(date)}
          </Typography>
          <Typography sx={{ fontFamily: 'Whitney', fontSize: { lg: 14, md: 12, sm: 10 } }}>
            {unixToTime(date)}
          </Typography>
        </Stack>
      </TimelineOppositeContent>
      <TimelineSeparator>
        {mapped_category === 'Delivered' ? (
          <TimelineDot
            sx={{ marginTop: { lg: 2.8, md: 2.6, sm: 2.1 }, backgroundColor: 'none', padding: 0, marginLeft: '-5px' }}
          >
            <img src="/assets/icons/timeline/delivered.svg" style={{ maxWidth: 20, zIndex: 2 }} />
          </TimelineDot>
        ) : (
          <TimelineDot
            sx={{
              backgroundColor: 'primary.light',
              marginTop: { lg: 3.2, md: 2.9, sm: 2.6 },
              marginLeft: { lg: '0px', md: '0px', sm: '-2px' },
            }}
          />
        )}

        {isLast ? null : (
          <TimelineConnector
            sx={{
              height: 70,
              position: 'absolute',
              marginTop: { lg: '37px', md: '35px', sm: '32px' },
              bgcolor: '#ACACAC',
              marginLeft: { lg: '-1px', md: '-1px', sm: '-2px' },
            }}
          />
        )}
      </TimelineSeparator>

      <TimelineContent>
        <Typography sx={{ fontFamily: 'Whitney', width: { sm: 100, lg: 210 }, fontSize: { lg: 14, md: 12, sm: 10 } }}>
          {status} ({mapped_category})
        </Typography>
        {isLast ? null : (
          <Typography sx={{ lineHeight: 22 / 14, fontFamily: 'Whitney', fontSize: { lg: 14, md: 12, sm: 10 } }}>
            {location.city ? `${location.city}, ` : null}
            {location.state ? `${location.state} ` : null}&nbsp;&nbsp;
            {location.postal_code ?? location.postal_code}
          </Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  item: PropTypes.shape({
    date: PropTypes.number,
    location: PropTypes.object,
    status: PropTypes.string,
  }),
};

function CollapseTableRow({ intputData, onExpandhandler, showLoader }) {
  const trackingNumber = intputData?.tracking_number;
  const StyledDetatilsRoot = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'left',
    minHeight: 300,
    padding: '0 !important',
    width: 'max-content',
    gap: 13,
    fontSize: { sm: 14, lg: 16 },
    fontFamily: 'Whitney',
  }));

  const StyledTableRow = styled(TableRow)(() => ({}));
  const StyledTableCell = styled(TableCell)(() => ({
    color: '#000',
    border: 'none',
    padding: 0,
    verticalAlign: 'top',
    fontSize: { sm: 14, lg: 16 },
    fontFamily: 'Whitney',
  }));
  const StyledHeadTableCell = styled(TableCell)(() => ({
    color: '#000',
    background: 'none',
    borderBottom: '1px solid #ACACAC',
    paddingLeft: 0,
    paddingBottom: 2,
    paddingTop: 3,
    paddingRight: 0,
    fontSize: 20,
    fontSize: { sm: 14, lg: 16 },
    fontFamily: 'Whitney',
  }));
  const StyledValueTableCell = styled(TableCell)(() => ({
    color: '#000',
    border: 'none',
    paddingLeft: 0,
    paddingBottom: 3,
    paddingTop: 3,
    paddingRight: 0,
    fontWeight: 600,
    verticalAlign: 'top',
    fontSize: { sm: 14, lg: 16 },
    fontFamily: 'Whitney',
  }));
  TableRow;
  const [openModal, setOpenModal] = useState(true);
  const handleCloseModal = (e) => {
    setOpenModal(false);
    onExpandhandler(e, trackingNumber, 'tracking_number');
  };

  return (
    <Modal open={openModal} onClose={handleCloseModal}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...popUpModalStyle,
        }}
      >
        {showLoader || Object.keys(intputData).length == 0 ? (
          <CircularProgress sx={{ color: 'primary.main' }} />
        ) : (
          typeof intputData != null && (
            <Stack
              direction="row"
              spacing={{ lg: 8, sm: 2, md: 2 }}
              sx={{
                color: '#000',
                padding: 4,
                fontSize: { sm: 14, lg: 16 },
                fontFamily: 'Whitney',
                backgroundColor: 'white',
                margin: 'auto',
              }}
            >
              <IconButton
                aria-label="close"
                sx={{
                  position: 'fixed',
                  top: { sm: 15, lg: 34 },
                  right: { lg: 29, md: 30, sm: 10 },
                  zIndex: 2,
                }}
                onClick={handleCloseModal}
              >
                <img width="34px" src="/assets/icons/table/close.svg" alt="X" />
              </IconButton>
              <StyledDetatilsRoot>
                {/* Order details */}
                <Table sx={{ width: { lg: 500, md: 440, sm: 290 } }} aria-label="Order Details table">
                  <TableHead>
                    <TableRow>
                      <StyledHeadTableCell colSpan={3}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle3">Order Details =</Typography>
                          {/* <Iconify sx={{ pt: { sm: 0.4 } }} icon="ic:outline-calendar-month" /> */}
                          <SvgColor
                            src="/assets/icons/navbar/tracking.svg"
                            color="common.black"
                            sx={{ width: 20, height: 20, px: 3 }}
                          />
                          <Typography variant="subtitle3">{intputData?.latest_status?.category}</Typography>
                        </Stack>
                      </StyledHeadTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          paddingTop: 1,
                          width: { sm: 122, md: 165, lg: 180 },
                          fontSize: { sm: 10, md: 14, lg: 18 },
                          fontFamily: 'Whitney',
                        }}
                        align="left"
                      >
                        Tracking Number
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: 15, paddingTop: 1, fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        align="left"
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        sx={{
                          color: 'primary.light',
                          paddingTop: 1,
                          fontSize: { sm: 10, md: 14, lg: 18 },
                          fontFamily: 'Whitney Semibold',
                        }}
                        align="left"
                      >
                        {intputData?.tracking_number}
                      </StyledValueTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        Carrier
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                      >
                        {intputData?.carrier ? intputData?.carrier : '--'}
                      </StyledValueTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        Order Number
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                      >
                        {intputData?.order_info?.map((item, i) =>
                          intputData.order_info.length === i + 1
                            ? item.order_details.increment_id
                            : `${item.order_details.increment_id}, `
                        )}
                      </StyledValueTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        Customers Name
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                      >
                        {intputData.customer_name}
                      </StyledValueTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        Address
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                      >
                        {intputData.shipping_city},&nbsp;&nbsp;&nbsp;{intputData.shipping_state}&nbsp;&nbsp;&nbsp;
                        {intputData.shipping_postal_code}
                      </StyledValueTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                {/* Estimated Time in Transit */}
                <Table sx={{ width: { lg: 305, md: 302, sm: 230 } }} aria-label="Estimated Time table">
                  <TableHead>
                    <TableRow>
                      <StyledHeadTableCell colSpan={3}>
                        <Typography variant="subtitle3">Time in Transit</Typography>
                      </StyledHeadTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        colSpan={3}
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 1,
                          fontSize: { sm: 14, md: 18, lg: 22 },
                          fontWeight: 600,
                          color: 'common.black',
                          fontFamily: 'Whitney Semibold',
                        }}
                        align="left"
                      >
                        {intputData?.delivered_date
                          ? `${dateDiff(
                              unixToDate(intputData.label_created_date),
                              unixToDate(intputData.delivered_date)
                            )} Days`
                          : `${dateDiff(
                              unixToDate(intputData.label_created_date),
                              unixToDate(moment(new Date()).format('X'))
                            )} Days`}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          width: { lg: 180, sm: 122, md: 165 },
                          fontSize: { sm: 10, md: 14, lg: 18 },
                          fontFamily: 'Whitney',
                        }}
                        align="left"
                      >
                        Shipped On
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: 15, fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        align="left"
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        align="left"
                        sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                      >
                        {unixToDate(intputData.label_created_date)}
                      </StyledValueTableCell>
                    </StyledTableRow>
                    {intputData?.delivered_date && (
                      <StyledTableRow>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        >
                          Delivered On
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        >
                          :
                        </StyledTableCell>
                        <StyledValueTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                        >
                          {intputData?.delivered_date ? unixToDate(intputData?.delivered_date) : '--'}
                        </StyledValueTableCell>
                      </StyledTableRow>
                    )}
                    {!intputData?.delivered_date && (
                      <StyledTableRow>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        >
                          Estimated Delivery
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        >
                          :
                        </StyledTableCell>
                        <StyledValueTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                        >
                          {intputData.estimated_delivery_date_new
                            ? unixToDate(intputData.estimated_delivery_date_new)
                            : unixToDate(intputData.estimated_delivery_date)}
                        </StyledValueTableCell>
                      </StyledTableRow>
                    )}
                    {intputData.estimated_delivery_date_new && (
                      <StyledTableRow>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        >
                          Original Est. Delivery
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        >
                          :
                        </StyledTableCell>
                        <StyledValueTableCell
                          align="left"
                          sx={{ fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney Semibold' }}
                        >
                          {intputData.estimated_delivery_date ? unixToDate(intputData.estimated_delivery_date) : '--'}
                        </StyledValueTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
                {/* Other Information */}
                <Table sx={{ width: { lg: 300, md: 220, sm: 170 } }} aria-label="Other Information table">
                  <TableHead>
                    <TableRow>
                      <StyledHeadTableCell colSpan={3}>
                        <Typography variant="subtitle3">Other Information</Typography>
                      </StyledHeadTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          width: { lg: 180, sm: 122, md: 165 },
                          paddingTop: 1,
                          fontSize: { sm: 10, md: 14, lg: 18 },
                          fontFamily: 'Whitney',
                        }}
                        align="left"
                      >
                        Shipping charges
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: 15, paddingTop: 1, fontSize: { sm: 10, md: 14, lg: 18 }, fontFamily: 'Whitney' }}
                        align="left"
                      >
                        :
                      </StyledTableCell>
                      <StyledValueTableCell
                        sx={{
                          paddingTop: 1,
                          fontSize: { sm: 10, md: 14, lg: 18 },
                          fontFamily: 'Whitney Semibold',
                          mt: 1,
                        }}
                        align="left"
                      >
                        ${' '}
                        {intputData.shipping_and_handling != 0 && intputData.shipping_and_handling != ''
                          ? intputData.shipping_and_handling.toFixed(2)
                          : '0.00'}
                      </StyledValueTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </StyledDetatilsRoot>
              <StyledDetatilsRoot>
                <Box sx={{ paddingLeft: 2 }}>
                  <Typography variant="subtitle3">Tracking Details</Typography>
                  <Divider sx={{ borderColor: '#ACACAC', marginTop: 1, width: 219 }} />
                </Box>
                <Timeline
                  sx={{
                    paddingLeft: 0,
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                >
                  {intputData?.events?.map((item, index) => (
                    <OrderItem
                      key={item.date}
                      item={item}
                      isLast={index === intputData.events.length - 1}
                      isFirst={index === 0}
                    />
                  ))}
                </Timeline>
              </StyledDetatilsRoot>
            </Stack>
          )
        )}
      </Box>
    </Modal>
  );
}

OrderItem.propTypes = {
  intputData: PropTypes.object,
  onExpandhandler: PropTypes.func,
};

export default CollapseTableRow;
