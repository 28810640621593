/* eslint-disable */
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import { useState, useEffect } from 'react';
//
import MailScans from './pages/MailScans';
import TrackingPage from './pages/TrackingPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ConfigPage from './pages/ConfigPage';
import Reports from './pages/Reports/Reports';
import DashboardAppPage from './pages/DashboardAppPage';
import { connect, useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getToken } from './utils/extractUserToken';
import { logoutUser } from './features/user/userState';
import { resetScanState } from './features/scan/scanState';
import { resetConfigState } from './features/config/configState';
import { resetTrackState } from './features/tracking/trakingState';
import { decrementAwaitingApiCounter } from './features/ui/uiState';
import AverageTimeTracker from './pages/Reports/AverageTimeTracker';
import ReplacementOrderPage from './pages/ReplacementOrder';
import ForecastReport from './pages/Reports/Forecast';

function Router({ user, ui }) {
  const [token, setToken] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchedToken = getToken();
    setToken(fetchedToken);
    if (!fetchedToken) {
      setToken('');
      dispatch(logoutUser());
      dispatch(resetScanState());
      dispatch(resetConfigState());
      dispatch(resetTrackState());
      // dispatch(decrementAwaitingApiCounter())
    }
  }, [token, user, ui]);
  const routes = createBrowserRouter([
    {
      path: 'login',
      element: token ? <Navigate to="/dashboard" /> : <LoginPage />,
    },
    {
      path: 'dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'tracking', element: <TrackingPage /> },
        { path: 'tracking/:trackingNumber', element: <TrackingPage /> },
        { path: 'tracking/filter/:status/:dashboardStartDate/:dashboardEndDate', element: <TrackingPage /> },
        { path: 'tracking/scan_filter/:first_scan/:dashboardStartDate/:dashboardEndDate', element: <TrackingPage /> },
        { path: 'mail_scans', element: <MailScans /> },
        { path: 'reports', element: <Reports /> },
        { path: 'reports/preshipment', element: <Reports /> },
        { path: 'reports/intransit', element: <Reports /> },
        { path: 'reports/forecast', element: <ForecastReport /> },
        { path: 'reports/average_intransit_time', element: <AverageTimeTracker /> },
        { path: 'replace-order', element: <ReplacementOrderPage /> },
        {
          path: 'config',
          element: user?.userInfo?.role?.role_name == 'Administrators' ? <ConfigPage /> : <Navigate to="/dashboard" />,
        },
      ],
    },
    {
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        // {path: '404', element: <Page404 /> },
        // {path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '*',
    //   element: <Navigate to="/404" replace />,
    // },
  ]);

  return <RouterProvider router={routes} />;
}

Router.propTypes = {
  user: PropTypes.shape({}),
};
const mapDispatch = (state) => ({
  user: state.user,
  ui: state.ui,
});

export default connect(mapDispatch)(Router);
