import { InputAdornment, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import Iconify from '../../../components/iconify';

export default function SearchbarNew() {
  const navigate = useNavigate();
  const onHandleSubmit = (e) => {
    e.preventDefault();
    navigate(`/dashboard/tracking/${e.target.trackingNumber.value}`);
  };

  const StyledRoot = styled(TextField)(({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.common.white,
    width: 450,
    height: 56,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.main,
    '& :nth-of-type(1)': {
      paddingRight: 0,
    },
    '& > div > input': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('lg')]: {
      height: 52,
      '& .MuiInputBase-root': {
        height: 52,
      },
      '& :nth-of-type(1)': {
        // paddingRight: theme.spacing(0.3),
      },
    },
    [theme.breakpoints.down('md')]: {
      width: 326,
      height: 38,
      '& .MuiInputBase-root': {
        height: 38,
      },
      '& :nth-of-type(1)': {
        // paddingRight: theme.spacing(0.3),
      },
    },
  }));

  const StyledButton = styled(Button)(({ theme }) => ({
    boxShadow: 'none',
    background: theme.palette.primary.light,
    color: theme.palette.text.primary,
    height: theme.spacing(5.5),
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    '& .MuiButton-startIcon svg': {
      width: 25,
      height: 25,
    },
    [theme.breakpoints.down('lg')]: {
      width: 40,
      height: 30,
      minWidth: 40,
      marginRight: 0,
      '& .MuiButton-startIcon svg': {
        width: 18,
        height: 18,
      },
    },
  }));

  return (
    <form onSubmit={onHandleSubmit}>
      <StyledRoot
        required
        variant="outlined"
        name="trackingNumber"
        placeholder="Enter your track no or order no"
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                variant="contained"
                type="submit"
                sx={{
                  boxShadow: 'none',
                  backgroundColor: 'primary.light',
                  color: 'text.primary',
                  alignContent: 'center',
                  justifyContent: 'center',
                  borderRadius: 1,
                  padding: 0,
                  marginRight: { lg: 1, md: 1, sm: .6 },
                  height: { sm: 29, md: 40, lg: 42.4 },
                  width: { sm: 40.6, md: 56, lg: 56 },
                  minWidth: 30,
                  '& .MuiButton-startIcon': {
                    margin: 0,
                    padding: 0,
                  },
                }}
                startIcon={
                  <Iconify
                    sx={{
                      width: { lg: 26, md: 27, sm: 18 },
                      height: { lg: 27, md: 27, sm: 18 },
                      margin: 0,
                      padding: 0,
                      color: 'text.primary',
                    }}
                    icon="eva:search-fill"
                  />
                }
              />
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
}
