import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useEffect, useState } from 'react';

export default function ReportsLabel() {
  const [text, setText] = useState('');
  const { pathname } = useLocation();

  const reportLabels = {
    '/dashboard/reports/preshipment': 'Preshipment Alert',
    '/dashboard/reports/intransit': 'In Transit Alert',
    '/dashboard/reports/average_intransit_time': 'Average Time InTransit',
    '/dashboard/reports/forecast': 'Forecast Summary',
  };

  useEffect(() => {
    setText(reportLabels[pathname] || '');
  }, [pathname]);

  return (
    <Box sx={{ color: '#000', fontFamily: 'Whitney Semibold', fontSize: '36px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>
      {text}
    </Box>
  );
}
