import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { getForecastReport } from 'src/features/tracking/trackingApi';
import DateRangePicker from 'src/components/date-picker/DateRangePicker';
import InputForm from 'src/components/input-field/InputField';
import { incrementAwaitingApiCounter, decrementAwaitingApiCounter } from 'src/features/ui/uiState';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    Box,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Typography,
} from '@mui/material';
import { logoutUser } from 'src/features/user/userState';


export default function ForecastReport() {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day').format('MM/DD/YYYY'));
    const [endDate, setEndDate] = useState(dayjs().add(7, 'day').format('MM/DD/YYYY'));
    const [advancedFormData, setAdvancedFromData] = useState(0);
    const [tableData, setTableData] = useState([
        {
            recurring_order_count: 0,
            child_order_count: 0,
            product_combinations: []
        }
    ]);
    const dispatch = useDispatch();
    const getForecastData = () => {
        dispatch(incrementAwaitingApiCounter());
        let params = {
            startDate: dayjs(startDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            endDate: dayjs(endDate, 'MM/DD/YYYY').format('YYYY-MM-DD'),
            childOrderCount: advancedFormData
        };
        let response = getForecastReport(params);
        response.then((res) => {
            setTableData(res?.data);
            dispatch(decrementAwaitingApiCounter());

        }).catch((error) => {
            console.log(error);
            if (error?.response?.status == '401') {
                dispatch(logoutUser());
            }
            toast.error(error?.message || 'Something went wrong', {
                autoClose: 6000,
                theme: 'colored',
            });
            dispatch(decrementAwaitingApiCounter());
        });
    };

    useEffect(() => {
        getForecastData();
    }, []);

    const onDateChange = (dates) => {
        if (dates != null) {
            const formattedStartDate = dayjs(dates[0]).format('MM/DD/YYYY');
            const formattedEndDate = dayjs(dates[1]).format('MM/DD/YYYY');
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
        }
    };

    const onChangeAdvancedForm = (e) => {
        const { name, value } = e.target;
        setAdvancedFromData(value);
    };

    const handleApplyClick = async () => {
        getForecastData();
    };
    const { recurring_order_count, product_combinations, child_order_count } = tableData[0];

    const StyledTableHead = styled(TableHead)(({ theme }) => ({
        '& .MuiTableRow-head > .MuiTableCell-head': {
            color: theme.palette.common.black,
        },
        '& .MuiTableRow-head > .MuiTableCell-head> .MuiTableSortLabel-root:hover': {
            color: theme.palette.common.black,
        },
    }));

    const StyledTableCell = styled(TableCell)(() => ({
        width: 'auto',
        fontFamily: 'Whitney Semibold',
        fontSize: '16px',
        fontWeight: '500',
    }));

    const StyledTableBody = styled(TableBody)(({ theme }) => ({
        color: theme.palette.common.black,
        fontSize: { sm: 14, lg: 16 },
        fontFamily: 'Whitney',
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        color: theme.palette.common.black,
        fontSize: { sm: 14, lg: 16 },
        fontFamily: 'Whitney',
    }));

    return (
        <>
            <Helmet>
                <title> Forecast | Shipping Insights </title>
            </Helmet>
            <Container maxWidth="xl">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '1.5rem',
                        marginBottom: '1rem',
                    }}>
                    <div>
                        <DateRangePicker
                            startDate={startDate}
                            endDate={endDate}
                            label='Next Recurring Date'
                            onDateChange={onDateChange}
                        />
                    </div>
                    <div>
                        <InputForm
                            type="number"
                            placeholder='Child Order Count'
                            onChangeAdvancedForm={onChangeAdvancedForm}
                            advancedFormData={advancedFormData}
                            sx={{ height: 41.9, '& input': { height: 9, color: "#000" }, marginTop: { lg: 0 } }}
                        />
                    </div>
                    <div>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={handleApplyClick}
                            sx={{
                                display: 'flex',
                                width: { lg: '98px', md: '85px', sm: '63px' },
                                height: { lg: 41.9, sm: '33px' },
                                padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: 'none',
                                flexShrink: 0,
                                bgcolor: 'primary.light',
                                textAlign: 'center',
                                fontFamily: 'Whitney',
                                fontSize: { lg: '16px !important', sm: '14px' },
                                fontWeight: 500,
                                ':hover': {
                                    backgroundColor: '#479da6d6',
                                },
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </Box>
                <div>
                    <div style={{ padding: '20px', maxWidth: '900px', margin: '0 auto', color: "#000000" }}>
                        <Typography
                            variant="h6"
                            sx={{ color: 'common.black', lineHeight: 1, fontSize: { lg: 16, sm: 12, md: 12 } }}
                        >
                            Recurring Order Count: {recurring_order_count}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ color: 'common.black', lineHeight: 2, fontSize: { lg: 16, sm: 12, md: 12 }, paddingBottom: 3 }}
                        >
                            Child Order Count: {child_order_count}
                        </Typography>
                        <Paper sx={{ width: '100%', mb: 2, boxShadow: 'none' }}>
                            <TableContainer sx={{ width: 'auto' }}>
                                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                                    <StyledTableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                key='product_combinations'
                                                align="center"
                                                padding="normal"
                                                sx={{ paddingLeft: 5 }}
                                            >
                                                Product Combination
                                            </StyledTableCell>
                                            <StyledTableCell
                                                key='count'
                                                align="center"
                                                padding="normal"
                                                sx={{ paddingLeft: 5 }}
                                            >
                                                Count
                                            </StyledTableCell>
                                        </TableRow>
                                    </StyledTableHead>
                                    <StyledTableBody className="main">
                                        {product_combinations.map((item, index) => {
                                            return (
                                                <TableRow
                                                    hover={false}
                                                    role="checkbox"
                                                    key={index}
                                                    sx={{
                                                        background: index % 2 && '#00000008'
                                                    }}
                                                >
                                                    <StyledTableBodyCell
                                                        component="th"
                                                        scope="row"
                                                        padding="checkbox"
                                                        align="left"
                                                        sx={{
                                                            fontSize: '1rem',
                                                            paddingLeft: 5,
                                                            paddingBottom: 1,
                                                            paddingTop: 1
                                                        }}
                                                    > {item?.product_combinations}</StyledTableBodyCell>
                                                    <StyledTableBodyCell
                                                        component="th"
                                                        scope="row"
                                                        padding="checkbox"
                                                        align="left"
                                                        sx={{
                                                            fontSize: '1rem',
                                                            paddingLeft: 15,
                                                            paddingTop: 1,
                                                            paddingBottom: 1
                                                        }}
                                                    > {item?.count}</StyledTableBodyCell>
                                                </TableRow>
                                            );
                                        })}
                                    </StyledTableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </div>
            </Container >
        </>
    );
}