import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getTrackingList from './trackingApi';

const initialState = {
  trackList: {
    total_count: 0,
    current_page: 0,
    items: [
      {
        tracking_number: '',
        carrier: '',
        order_id: [],
        first_name: '',
        status: '',
      },
    ],
  },
  isFromCsv: false,
  csvName: '',
  csvDetails: null,
};

export const getTracking = createAsyncThunk('tracking/list', async (data) => {
  const response = await getTrackingList(data);
  return response;
});

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    resetTrackState: (state) => initialState,
    resetFromCsv: (state) => {
      state.isFromCsv = false;
      state.csvName = '';
      state.csvDetails = null;
    },
    updateCsvInfo: (state, action) => {
      state.isFromCsv = true;
      state.csvName = action.payload.name;
      state.csvDetails = action.payload.details;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTracking.pending, (state, action) => {
        state.trackList = action.payload;
      })
      .addCase(getTracking.fulfilled, (state, action) => {
        state.trackList = action.payload.data;
      })
      .addCase(getTracking.rejected, (state, action) => {
        state.trackList = action.payload;
      });
  },
});

export const { resetTrackState, resetFromCsv, updateCsvInfo } = trackingSlice.actions;

export const getTrackingInfo = (state) => state.tracking;
export const getCsvFileName = (state) => state.trackInfo.csvName;
export const getCsvFileStatus = (state) => state.trackInfo.isFromCsv;
export const getCsvDetails = (state) => state.trackInfo.csvDetails;

export default trackingSlice.reducer;
